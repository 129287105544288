import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from '../../../Api'

export const fetchTasks = createAsyncThunk('tasks/fetch', async () => {
  const response = await BASE_API.get('/user/tasks')
  return response?.data?.data
})

export const updateTask = createAsyncThunk('tasks/update', async ({id, status}, { dispatch }) => {
  const response = await BASE_API.post(`/user/tasks/${id}`, {status})
  dispatch(fetchTasks())
  return response?.data?.data
})

export const tasks = createSlice({
  name: 'tasks',
  initialState: {
    tasks: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.tasks = action.payload
      })
  }
})

export default tasks.reducer
