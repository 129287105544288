import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  notifications: []
}

export const notifications = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
      updateNotifications: (state, action) => {
        const notifications = action.payload?.result 
        if (!state.notifications.includes(notifications)) {
          state.notifications.push(notifications)
        }
      },
      readOneNotification: (state, action) => {
        const notificationId = action.payload?.item?.id
        const notifications = action.payload?.notifications 
        state.notifications = notifications?.filter(n => n?.id !== notificationId)
      },
      readAllNotifications: (state) => {
        state.notifications = []
      }
    }
})

export const { updateNotifications, readAllNotifications, readOneNotification} = notifications.actions

export default notifications.reducer