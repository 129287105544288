import { ls } from '@src/Helpers/LocalStorageEncrypt'
import axios from 'axios'
import { handleLogout } from '../Pages/Auth/store'
import { store } from "@src/redux/store"
import { Notification } from "@src/Helpers/actions"

const API_URL = 'https://api.original-business.com/api/'

    const getToken = () => {
        const token = ls.getItem("token")
        return token
    }

    const getCrudHeaders = () => {
    const token = getToken()
    if (token) {
        return {
        Authorization: `bearer ${token}`,
        'Content-Type': "multipart/form-data"
        }
    } else {
        return {
        'Content-Type': "multipart/form-data"
        }
    }
    }
    const getBaseHeaders = () => {
    const token = getToken()
    if (token) {
        return {
        Authorization: `bearer ${token}`,
        'Content-Type': "application/json"
        }
    } else {
        return {
        'Content-Type': "application/json"
        }
    }
    }

    export const CRUD_API = axios.create({
        baseURL: API_URL,
        headers: getCrudHeaders()
    })
    export const BASE_API = axios.create({
        baseURL: API_URL,
        headers: getBaseHeaders()
    })

    const handleErrors = (code) => {
        if (code === 401) {
            store.dispatch(handleLogout({customMessage : Notification('Session Expired', 2000, 'error') }))
        }
    }
    
    CRUD_API.interceptors.response.use((res) => {
        return res
    }, (err) => {
        handleErrors(err?.response?.status)
        return Promise.reject(err)
    })
    BASE_API.interceptors.response.use((res) => {
        return res
    }, (err) => {
        handleErrors(err?.response?.status)
        return Promise.reject(err)
    })