import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { Notification } from "@src/Helpers/actions"
import { ls } from '@src/Helpers/LocalStorageEncrypt'
import { BASE_API } from "../../Api"

export const authMe = createAsyncThunk(
  "authMe",
  async (_, { fulfillWithValue, rejectWithValue }) => {
    const response = await BASE_API.get("/user/me")
    if (response?.status === 200) {
      return fulfillWithValue(response?.data)
    }
    return rejectWithValue(response)
  }
)

const initialState = {
  data: {},
  isLogin: false
}

export const user = createSlice({
    name: 'user',
    initialState,
    reducers: {
      updateProfile: (state, action) => {
        state.isLogin = true 
        state.data = action?.payload
      },
      handleLogout: (state, action) => {
        const cusotmMessage = action.payload || Notification('Logged-out Successfully', 2000, 'success') 
        state.isLogin = false 
        state.data = {}
        ls.removeItem('token')
        // eslint-disable-next-line no-unused-expressions
        cusotmMessage
      }
    },
    extraReducers: (builder) => {
      builder
      .addCase(authMe.fulfilled, (state, action) => {
        state.data = action.payload
      }) 
      .addCase(authMe.rejected, (state) => {
        state.isLogin = false 
        state.data = {}
        window.location.pathname = '/login'
      }) 
    }
})

export const { updateProfile, handleLogout} = user.actions

export default user.reducer