import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux"
import { reducers } from './rootReducer'
import storage from 'redux-persist-indexeddb-storage'
import { persistReducer, persistStore } from "redux-persist"
import createCompressEncryptor from 'redux-persist-transform-compress-encrypt'

const baseReducers = combineReducers(reducers)
const transformer = createCompressEncryptor({
  secretKey: 'Original_M17'
})

const persistedReducer = persistReducer(
    {
      key: 'root',
      transforms: [transformer],
      storage: storage('Original')
    }
    ,
    baseReducers
)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
          serializableCheck: false,
          immutableCheck: true
        })
    }
})

export const persistor = persistStore(store)