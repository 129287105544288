import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_API } from "@src/Api"
import { filterBuilder } from '../../../Helpers/actions'

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async (filters) => {
  const queryString = ['Call', 'Online Meeting', 'Offline Meeting']?.includes(filters[0]) ? filterBuilder(filters, true) : undefined
  const api = queryString ? `events?${queryString}` : 'events'
  const response = await BASE_API.get(api)
  return response?.data?.data
})

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    events: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload || []
      })
  }
})

export default calendarSlice.reducer
