// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import calendar from '@src/Pages/Calendar/store'
import user from '@src/Pages/Auth/store'
import notifications  from './helpers'
import tasks from '../Pages/Tasks/store'

export const reducers = {
  navbar,
  layout,
  calendar,
  user,
  notifications,
  tasks
}
